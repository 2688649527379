import React from 'react';
import Grid from '@material-ui/core/Grid';

import Layout from '../components/layout';
import SEO from '../components/seo';

import amazonLogo from '../images/links/01.svg';
import iTunesLogo from '../images/links/02.svg';
import facebookLogo from '../images/links/03.svg';
import twitterLogo from '../images/links/04.svg';
import linkedInLogo from '../images/links/05.svg';
import bishopsGateLogo from '../images/links/06.svg';
import instagramLogo from '../images/links/07.svg';

const LinksPage: React.SFC = () => (
  <Layout pageTitle="Links">
    <SEO title="Links" />
    <Grid container spacing={8} justify="center" alignItems="center" alignContent="center" style={{minHeight: '400px', textAlign: 'center', width: '100%'}}>
      <Grid item xs>
        <a href="https://www.amazon.com/s/ref=ntt_srch_drd_B00HFN5V5Y?ie=UTF8&field-keywords=Stilson%20Greene&index=digital-music&search-type=ss">
          <img width={90} src={amazonLogo} alt="Amazon" />
        </a>
      </Grid>
      <Grid item xs>
        <a href="https://itunes.apple.com/us/artist/stilson-greene/786759232">
          <img width={100} src={iTunesLogo} alt="iTunes" />
        </a>
      </Grid>
      <Grid item xs>
        <a href="https://www.facebook.com/Stilson-Greene-Graphic-Design-Illustration-194677183843/?ref=ts">
          <img width={64} src={facebookLogo} alt="Facebook" />
        </a>
      </Grid>
      <Grid item xs>
        <a href="https://twitter.com/stilsongreene">
          <img width={64} src={twitterLogo} alt="Twitter" />
        </a>
      </Grid>
      <Grid item xs>
        <a href="https://www.instagram.com/stilsongreene">
          <img width={64} src={instagramLogo} alt="Instagram" />
        </a>
      </Grid>
      <Grid item xs>
        <a href="https://www.linkedin.com/in/stilson-greene-7214a37/">
          <img width={64} src={linkedInLogo} alt="LinkedIn" />
        </a>
      </Grid>
      <Grid item xs>
        <a href="http://atbishopsgate.com/">
          <img width={167} src={bishopsGateLogo} alt="at Bishopsgate" />
        </a>
      </Grid>
    </Grid>
  </Layout>
);

export default LinksPage;
